<template>
  <div class="bill_info_table">
    <a-spin :spinning="loading" tip="数据加载中" size="large">
      <a-form-model :model="form" ref="billInfoForm" :rules="rules">
        <xz-table
          :columns="columns"
          :data="form.billList"
          :scroll="{ x: 0, y: 540 }"
          :editable="true"
          rowKey="uniqueValue"
          :enableRowSelection="true"
          @change="handleSelectChange"
        >
          <template #buttons>
            <a-button type="primary" icon="plus" @click="newBill">新增行</a-button>
            <a-button type="primary" icon="plus" :disabled="!selectedKeys.length" @click="batchImport">
              批量录入
            </a-button>
            <a-button @click="importByExcel">表格导入</a-button>
          </template>
          <span slot="type" slot-scope="text, record, index">
            <a-form-model-item :prop="'billList.' + index + '.type'" :rules="rules.type">
              <a-select
                v-model="record.type"
                placeholder="请选择"
                style="width: 100%"
                @change="v => handleChange(v, record)"
              >
                <a-select-option v-for="opt in quotationItems" :key="opt.value" :value="opt.value">
                  {{ opt.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </span>
          <span slot="name" slot-scope="text, record, index">
            <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.name'" :rules="rules.name">
              <a-input v-model="record.name" placeholder="请输入" />
            </a-form-model-item>
            <span v-else>/</span>
          </span>
          <span slot="deviceRegisterCode" slot-scope="text, record, index">
            <a-form-model-item
              v-if="record.type === '2'"
              :prop="'billList.' + index + '.deviceRegisterCode'"
              :rules="rules.deviceRegisterCode"
            >
              <a-input v-model="record.deviceRegisterCode" placeholder="请输入" />
            </a-form-model-item>
            <span v-else>/</span>
          </span>
          <span slot="taxPrice" slot-scope="text, record, index">
            <a-form-model-item :prop="'billList.' + index + '.taxPrice'" :rules="rules.taxPrice">
              <a-input-number
                v-model="record.taxPrice"
                placeholder="请输入"
                :min="0"
                :step="0.01"
                style="width: 100%"
                :precision="2"
                @change="() => handleTotalMount(record)"
              />
            </a-form-model-item>
          </span>
          <span slot="quantity" slot-scope="text, record, index">
            <a-form-model-item
              v-if="record.type === '2'"
              :prop="'billList.' + index + '.quantity'"
              :rules="rules.quantity"
            >
              <a-input-number
                v-model="record.quantity"
                placeholder="请输入"
                :min="0"
                :step="1"
                style="width: 100%"
                :precision="0"
                @change="() => handleTotalMount(record)"
              />
            </a-form-model-item>
            <span v-else>{{ record.quantity }}</span>
          </span>
          <span slot="unit" slot-scope="text, record, index">
            <a-form-model-item v-if="record.type === '2'" :prop="'billList.' + index + '.unit'" :rules="rules.unit">
              <a-select v-model="record.unit" placeholder="请选择" style="width: 100%">
                <a-select-option v-for="opt in measureUnitCache" :key="opt.optionName" :value="opt.optionName">
                  {{ opt.optionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <span v-else>{{ record.unit }}</span>
          </span>
          <span slot="warrantyPeriod" slot-scope="text, record, index">
            <a-form-model-item
              v-if="record.type === '2'"
              :prop="'billList.' + index + '.warrantyPeriod'"
              :rules="rules.warrantyPeriod"
            >
              <div style="display: flex">
                <a-input-number
                  v-model="record.warrantyPeriod"
                  placeholder="请输入"
                  :min="0"
                  :step="0.01"
                  style="width: 100%"
                  :precision="2"
                />
                <a-select
                  v-model="record.warrantyPeriodUnit"
                  placeholder="请选择"
                  style="width: 100%; margin-left: 6px"
                >
                  <a-select-option v-for="opt in qualityAssTypeCache" :key="opt.optionName" :value="opt.optionName">
                    {{ opt.optionName }}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-model-item>
            <span v-else>/</span>
          </span>
          <span slot="taxAmount" slot-scope="text, record">
            <a-form-model-item>
              {{ record.taxAmount }}
            </a-form-model-item>
          </span>
          <span slot="action" slot-scope="text, record, index">
            <a @click="deleteBIll(index)">删除</a>
          </span>
        </xz-table>
      </a-form-model>
    </a-spin>
    <a-modal
      v-model="isImportByExcel"
      title="导入"
      :width="1300"
      :bodyStyle="{ padding: '6px 12px' }"
      centered
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
    >
      <ExcelHandle
        :config="ExcelTableConfig"
        :downloadUrl="downloadUrl"
        @cancel="cancelExcel"
        @confirm="confirmExcel"
      ></ExcelHandle>
    </a-modal>
    <a-modal
      v-model="isBatchImport"
      title="批量录入"
      :width="560"
      :bodyStyle="{ padding: '6px 12px' }"
      :maskClosable="false"
      :destroyOnClose="true"
      :footer="null"
    >
      <batch-import
        :config="batchConfig"
        @cancel="() => (isBatchImport = false)"
        @confirm="handleBatchImportConfirm"
      ></batch-import>
    </a-modal>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { mapGetters } from 'vuex';
import ExcelHandle from '@/components/excelHandle/index.vue';
import _ from 'lodash';
import BatchImport from '@/components/batchImport/index.vue';

const validateNum = (rule, value, callback) => {
  if (parseFloat(value) && Number(value)) {
    if (parseFloat(value) < 0) {
      callback(new Error());
    } else {
      callback();
    }
  } else if (rule.required) {
    callback(new Error());
  } else if (!rule.required) {
    if (!Number(value) && value !== undefined && value !== '' && value !== 0 && value !== null) {
      return callback(new Error());
    }
  }

  callback();
};

export default {
  name: 'BillInfoTable',
  components: {
    xzTable,
    ExcelHandle,
    BatchImport,
  },
  props: {
    provideBillData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['measureUnitCache', 'jifeiLeimuCache', 'qualityAssTypeCache']),
    batchConfig () {
      return [{
        title: '质保期/保修期',
        key: 'warrantyPeriod',
        _type: 'number',
        default: undefined,
        min: 0,
        step: 0.01,
        precision: 2,
        subs: [
          {
            title: '单位',
            key: 'warrantyPeriodUnit',
            _type: 'select',
            default: undefined,
            _options: this.qualityAssTypeCache.map(q => ({
              label: q.optionName,
              value: q.optionName,
            })),
          },
        ],
      }];
    },
    ExcelTableConfig () {
      return [
        {
          title: '计费类目',
          dataIndex: 'type',
          key: 'type',
          width: 100,
          ellipsis: true,
          _type: 'select',
          _options: [
            {
              label: '人工',
              value: '人工',
            },
            {
              label: '备件',
              value: '备件',
            },
          ],
          scopedSlots: { customRender: 'type' },
          rules: [{ required: true, message: '请选择设备类目', trigger: 'change' }],
          handleChange (v, record) {
            if (v === '人工') {
              // 人工
              record.name = undefined;
              record.deviceRegisterCode = undefined;
              record.taxPrice = undefined;
              record.quantity = 1;
              record.unit = '项';
              record.warrantyPeriod = undefined;
              record.warrantyPeriodUnit = undefined;
              record.taxAmount = undefined;
            } else {
              // 备件
              record.name = undefined;
              record.deviceRegisterCode = undefined;
              record.taxPrice = undefined;
              record.quantity = undefined;
              record.unit = this.defaultUnit;
              record.warrantyPeriod = undefined;
              record.warrantyPeriodUnit = '月';
              record.taxAmount = undefined;
            }
          },
          default: '人工',
        },
        {
          title: '计费项名称',
          dataIndex: 'name',
          key: 'name',
          width: 100,
          ellipsis: true,
          _type: 'input',
          setRules (row) {
            return [{ required: row.type === '备件', message: '请输入计费项名称', trigger: 'blur' }];
          },
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '零备件号',
          dataIndex: 'deviceRegisterCode',
          key: 'deviceRegisterCode',
          width: 100,
          ellipsis: true,
          _type: 'input',
          scopedSlots: { customRender: 'deviceRegisterCode' },
        },
        {
          title: '含税单价（元）',
          dataIndex: 'taxPrice',
          key: 'taxPrice',
          width: 110,
          ellipsis: true,
          _type: 'number',
          min: 0.01,
          step: 0.01,
          precision: 2,
          scopedSlots: { customRender: 'taxPrice' },
          rules: [{required: true, message: '请输入含税单价', validator: validateNum, trigger: 'blur'}],
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          width: 100,
          ellipsis: true,
          _type: 'number',
          min: 1,
          step: 1,
          precision: 0,
          scopedSlots: { customRender: 'quantity' },
          rules: [{ required: true, message: '请输入数量', validator: validateNum, trigger: 'blur' }],
          default: 1,
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: 80,
          ellipsis: true,
          _type: 'select',
          _options: this.measureUnitCache.map(q => ({
            label: q.optionName,
            value: q.optionName,
          })),
          scopedSlots: { customRender: 'unit' },
          setRules (row) {
            return [{ required: row.type === '备件', message: '请选择单位', trigger: 'change' }];
          },
          default: '项',
        },
        {
          title: '质保期/保修期',
          dataIndex: 'warrantyPeriod',
          key: 'warrantyPeriod',
          width: 120,
          ellipsis: true,
          _type: 'number',
          min: 0,
          step: 0.01,
          precision: 2,
          inputWidth: '80px',
          setRules (row) {
            return [{ required: row.type === '备件', message: '请输入质保期/保修期', validator: validateNum, trigger: 'change' }];
          },
          scopedSlots: { customRender: 'warrantyPeriod' },
          subs: [{
            title: '质保期/保修期单位',
            key: 'warrantyPeriodUnit',
            width: '60px',
            _type: 'select',
            _options: this.qualityAssTypeCache.map(q => ({
              label: q.optionName,
              value: q.optionName,
            })),
          }],
        },
      ];
    },
  },
  watch: {
    provideBillData: {
      handler (v) {
        const billList = [];

        v.forEach(bill => {
          if (bill.uniqueValue) {
            billList.push({...bill});
          } else {
            billList.push({
              ...bill,
              uniqueValue: _.uniqueId(),
            });
          }
        });

        this.form.billList = billList;
      },
      immediate: true,
    },
    measureUnitCache: {
      handler (v) {
        const defaultUnit = v.filter(v => v.defaultFlag);

        this.defaultUnit = defaultUnit?.[0]?.optionName;
      },
      immediate: true,
    },
  },
  data () {
    const validateTaxPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error('请输入'));
      } else if (value === 0) {
        callback(new Error('含税单价不能为0'));
      }

      callback();
    };

    return {
      form: {
        billList: [],
      },
      quotationItems: [
        {
          name: '人工',
          value: '1',
        },
        {
          name: '备件',
          value: '2',
        },
      ],
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '计费类目',
          dataIndex: 'type',
          key: 'type',
          width: 100,
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '计费项名称',
          dataIndex: 'name',
          key: 'name',
          width: 160,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '零备件号',
          dataIndex: 'deviceRegisterCode',
          key: 'deviceRegisterCode',
          width: 170,
          scopedSlots: { customRender: 'deviceRegisterCode' },
        },
        {
          title: '含税单价（元）',
          dataIndex: 'taxPrice',
          key: 'taxPrice',
          scopedSlots: { customRender: 'taxPrice' },
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          scopedSlots: { customRender: 'quantity' },
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          width: 90,
          scopedSlots: { customRender: 'unit' },
        },
        {
          title: '质保期/保修期',
          dataIndex: 'warrantyPeriod',
          width: 170,
          key: 'warrantyPeriod',
          scopedSlots: { customRender: 'warrantyPeriod' },
        },
        {
          title: '含税金额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          width: 100,
          scopedSlots: { customRender: 'taxAmount' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        taxPrice: [{ required: true, validator: validateTaxPrice, trigger: 'blur' }],
        quantity: [{ required: true, message: '请输入', trigger: 'blur' }],
        unit: [{ required: true, message: '请输入', trigger: 'blur' }],
        warrantyPeriod: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      defaultUnit: '',
      isImportByExcel: false,
      downloadUrl: {
        name: '计费清单导入模版',
        url: `${process.env.VUE_APP_HOST}10000000000/计费清单导入模版.xlsx`,
      },
      selectedKeys: [],
      isBatchImport: false,
      loading: false,
    };
  },
  methods: {
    // 表格导入
    importByExcel () {
      this.isImportByExcel = true;
    },
    newBill () {
      // 收款计划
      this.form.billList.push({
        type: '1',
        name: undefined,
        deviceRegisterCode: undefined,
        taxPrice: undefined,
        quantity: 1,
        unit: '项',
        warrantyPeriod: undefined,
        warrantyPeriodUnit: undefined,
        taxAmount: undefined,
        uniqueValue: _.uniqueId(),
      });
    },
    deleteBIll (index) {
      const uniqueValue = this.form.billList[index].uniqueValue;

      if (this.selectedKeys.includes(uniqueValue)) {
        const index = this.selectedKeys.findIndex(k => k === uniqueValue);

        if (index !== -1) {
          this.selectedKeys.splice(index, 1);
        }
      }

      this.form.billList.splice(index, 1);
    },
    handleChange (v, record) {
      if (v === '1') {
        // 人工
        record.name = undefined;
        record.deviceRegisterCode = undefined;
        record.taxPrice = undefined;
        record.quantity = 1;
        record.unit = '项';
        record.warrantyPeriod = undefined;
        record.warrantyPeriodUnit = undefined;
        record.taxAmount = undefined;
      } else {
        // 备件
        record.name = undefined;
        record.deviceRegisterCode = undefined;
        record.taxPrice = undefined;
        record.quantity = undefined;
        record.unit = this.defaultUnit;
        record.warrantyPeriod = undefined;
        record.warrantyPeriodUnit = '月';
        record.taxAmount = undefined;
      }
    },
    handleTotalMount (record) {
      record.taxAmount = record.taxPrice && record.quantity ? Math.round(record.taxPrice * 100 * record.quantity * 100) / 10000 : undefined;
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.billInfoForm.validate(async valid => {
          if (valid) {
            this.$emit('update:provideBillData', this.form.billList);
            resolve(valid);
          } else {
            reject(new Error('fail'));
          }
        });
      });
    },
    cancelExcel () {
      this.isImportByExcel = false;
    },
    dealWithExcelData (bills) {
      return new Promise(resolve => {
        const billData = bills.map(b => {
          const quantity = b.type === '人工' ? 1 : b.type === '备件' ? Math.round(b.quantity) : undefined;
          const taxPrice = b.taxPrice || b.taxPrice === 0 ? Math.round(b.taxPrice * 100) / 100 : undefined;

          return {
            type: b.type === '人工' ? '1' : b.type === '备件' ? '2' : undefined,
            name: b.type === '人工' ? undefined : b.type === '备件' ? b.name : undefined,
            deviceRegisterCode: b.type === '人工' ? undefined : b.type === '备件' ? b.deviceRegisterCode : undefined,
            taxPrice,
            quantity,
            unit: b.type === '人工' ? '项' : b.type === '备件' ? b.unit : undefined,
            warrantyPeriod: b.type === '人工' ? undefined : b.type === '备件' ? b.warrantyPeriod : undefined,
            warrantyPeriodUnit: b.type === '人工' ? undefined : b.type === '备件' ? b.warrantyPeriodUnit : undefined,
            taxAmount: taxPrice && quantity ? Math.round(taxPrice * 100 * quantity) / 100 : undefined,
            uniqueValue: _.uniqueId(),
          };
        });
        resolve(billData);
      });
    },
    confirmExcel (bills) {
      return new Promise(resolve => {
        this.isImportByExcel = false;
        this.loading = true;
        resolve();
      }).then(() => {
        this.dealWithExcelData(bills)
          .then(res => {
            // 数据量大时，放入异步任务，防止页面渲染堵塞
            setTimeout(() => {
              this.form.billList = [...this.form.billList, ...res];
              this.loading = false;
            }, 1000);
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    handleSelectChange (_, rows) {
      this.selectedKeys = rows.map(r => r.uniqueValue);
    },
    batchImport () {
      this.isBatchImport = true;
    },
    handleBatchImportConfirm (v) {
      const selectedData = this.form.billList.filter(bill => this.selectedKeys.includes(bill.uniqueValue));

      for (const k in v) {
        selectedData.forEach(selected => {
          if (selected.type === '2') { // 备件需要质保期/保修期相关信息
            selected[k] = v[k];
          }
        });
      }

      this.isBatchImport = false;
    },
  },
  created () {},
};
</script>

<style scoped lang="scss">
.bill_info_table {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  /deep/ .ant-form-item {
    margin-bottom: 12px;

    .ant-form-explain {
      display: none;
    }
  }

  /deep/ .ant-table-tbody > tr > td {
    padding: 8px 16px 0;
    overflow-wrap: break-word;
  }

  &.text {
    display: flex;
    align-items: center;

    span {
      width: 5em;
    }

    .ant-input {
      flex: 1;
    }
  }
}
</style>

