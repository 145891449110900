<template>
  <div class="asset_paymentPlan_info_table">
    <a-form-model :model="form" ref="paymentPlanInfoForm" :rules="rules">
      <xz-table
        :columns="columns"
        :data="form.paymentPlanList"
        :scroll="{ x: 0, y: 540 }"
        :editable="true"
        rowKey="uniqueValue"
      >
        <template #buttons>
          <a-button type="primary" icon="plus" :disabled="!total" @click="newPaymentPlan">新增行</a-button>
          <span v-if="!total" style="font-size: 12px; color: red">
            <a-icon type="info-circle" style="margin-left: 4px" />
            含税金额不存在或为0，无法新增
          </span>
        </template>
        <span slot="paymentAmount" slot-scope="text, record, index">
          <a-form-model-item :prop="'paymentPlanList.' + index + '.paymentAmount'" :rules="rules.paymentAmount">
            <a-input-number
              v-model="record.paymentAmount"
              placeholder="请输入"
              :min="0"
              :step="0.01"
              style="width: 100%"
              :precision="2"
            />
          </a-form-model-item>
        </span>
        <span slot="paymentDate" slot-scope="text, record, index">
          <a-form-model-item :prop="'paymentPlanList.' + index + '.paymentDate'" :rules="rules.paymentDate">
            <a-date-picker
              v-model="record.paymentDate"
              valueFormat="x"
              style="width: 100%"
              allow-clear
            ></a-date-picker>
          </a-form-model-item>
        </span>
        <span slot="ratio" slot-scope="text, record">
          {{ handleRatio(record) }}
        </span>
        <span slot="action" slot-scope="text, record, index">
          <a @click="deletePaymentPlan(index)">删除</a>
        </span>
        <template slot="footer" slot-scope="currentPageData">
            <div>
              <b>合计：</b>
              {{ Math.round(currentPageData.reduce((pre, cur) => pre + cur.paymentAmount, 0) * 100) / 100 || '' }}
            </div>
          </template>
      </xz-table>
    </a-form-model>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import _ from 'lodash';

export default {
  name: 'HosipitalAssetPaymentPlanInfoTable',
  components: {
    xzTable,
  },
  props: {
    providePaymentPlanData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
    dateConfig: {
      type: Object,
      default: undefined,
    },
  },
  watch: {
    providePaymentPlanData: {
      handler (v) {
        const paymentPlanList = [];

        v.forEach(paymentPlan => {
          if (paymentPlan.uniqueValue) {
            paymentPlanList.push({ ...paymentPlan });
          } else {
            paymentPlanList.push({
              ...paymentPlan,
              uniqueValue: _.uniqueId(),
            });
          }
        });

        this.form.paymentPlanList = paymentPlanList;
      },
      immediate: true,
    },
  },
  data () {
    const validateTaxPrice = (rule, value, callback) => {
      if (!value && value !== 0) {
        callback(new Error('请输入'));
      } else if (value === 0) {
        callback(new Error('收款金额不能为0'));
      }

      callback();
    };

    return {
      form: {
        paymentPlanList: [],
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 60,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: '收款日期',
          dataIndex: 'paymentDate',
          key: 'paymentDate',
          width: 140,
          scopedSlots: { customRender: 'paymentDate' },
        },
        {
          title: '收款金额（元）',
          dataIndex: 'paymentAmount',
          key: 'paymentAmount',
          width: 160,
          scopedSlots: { customRender: 'paymentAmount' },
        },
        {
          title: '本项金额占比（%）',
          dataIndex: 'ratio',
          key: 'ratio',
          width: 170,
          scopedSlots: { customRender: 'ratio' },
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          width: 80,
          scopedSlots: { customRender: 'action' },
        },
      ],
      rules: {
        paymentDate: [{ required: true, message: '请输入', trigger: 'change' }],
        paymentAmount: [{ required: true, validator: validateTaxPrice, trigger: 'blur' }],
      },
    };
  },
  methods: {
    newPaymentPlan () {
      // 收款计划
      this.form.paymentPlanList.push({
        name: this.name,
        paymentDate: undefined,
        paymentAmount: undefined,
        ratio: undefined,
        uniqueValue: _.uniqueId(),
      });
    },
    deletePaymentPlan (index) {
      this.form.paymentPlanList.splice(index, 1);
    },
    handleRatio (record) {
      return record.paymentAmount && this.total ? Math.round((record.paymentAmount / this.total) * 100 * 100) / 100 : '';
    },
    validate () {
      return new Promise((resolve, reject) => {
        this.$refs.paymentPlanInfoForm.validate(valid => {
          if (valid) {
            this.$emit('update:providePaymentPlanData', this.form.paymentPlanList);
            resolve(valid);
          } else {
            reject(new Error('fail'));
          }
        });
      });
    },
  },
  created () {},
};
</script>

<style scoped lang="scss">
.asset_paymentPlan_info_table {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  /deep/ .ant-form-item {
    margin-bottom: 12px;

    .ant-form-explain {
      display: none;
    }
  }

  /deep/ .ant-table-tbody > tr > td {
    padding: 8px 16px 0;
    overflow-wrap: break-word;
  }

  &.text {
    display: flex;
    align-items: center;

    span {
      width: 5em;
    }

    .ant-input {
      flex: 1;
    }
  }
}
</style>

