<template>
  <div class="bill_info_wrapper">
    <div class="title gap-12 "><span v-if="required" style="color: #f5222d; margin-right: 4px;">*</span>计费清单<a-alert
        v-if="required && showWarning" message="计费清单不能为空" banner closable @close="showWarning = false" /></div>
    <xz-table ref="table" :columns="columns" :data="billList" :scroll="{ x: 0, y: 540 }" :locale="locale">
      <span slot="warrantyPeriod" slot-scope="text, record">
        {{ record.warrantyPeriod }} {{ record.warrantyPeriodUnit }}
      </span>
      <template slot="footer" slot-scope="currentPageData">
        <div>
          <b>含税总金额（元）：</b>{{ Math.round(currentPageData.reduce((pre, cur) => pre + cur.taxAmount, 0) * 100) / 100 || '' }}
        </div>
      </template>
    </xz-table>
  </div>
</template>

<script>
import xzTable from '@/components/xiaozi-ui/xz-table/src/table.vue';
import { mapGetters } from 'vuex';

const emptyImg = require('@/assets/empty.svg');

const QUOTATION_ENUM = ['', '人工', '备件'];

export default {
  name: 'BIllInfo',
  components: {
    xzTable,
  },
  props: {
    provideBillList: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters([]),
  },
  watch: {
    provideBillList: {
      handler (v) {
        this.billList = [...v];
      },
      immediate: true,
    },
  },
  data () {
    return {
      showWarning: false,
      billList: [],
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 80,
          ellipsis: true,
        },
        {
          title: '计费类目',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          customRender: (text) => {
            return QUOTATION_ENUM[text];
          },
        },
        {
          title: '计费项名称',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
        },
        {
          title: '零备件号',
          dataIndex: 'deviceRegisterCode',
          key: 'deviceRegisterCode',
          ellipsis: true,
        },
        {
          title: '含税单价（元）',
          dataIndex: 'taxPrice',
          key: 'taxPrice',
          ellipsis: true,
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          ellipsis: true,
        },
        {
          title: '单位',
          dataIndex: 'unit',
          key: 'unit',
          ellipsis: true,
        },
        {
          title: '质保期/保修期',
          dataIndex: 'warrantyPeriod',
          key: 'warrantyPeriod',
          scopedSlots: { customRender: 'warrantyPeriod' },
          ellipsis: true,
        },
        {
          title: '含税金额',
          dataIndex: 'taxAmount',
          key: 'taxAmount',
          ellipsis: true,
        },
      ],
      locale: {
        emptyText: () => (<a-empty image={emptyImg} description={'暂无数据，请在关联设备行录入报价单明细'}></a-empty>),
      },
    };
  },
  methods: {
    validate () {
      return new Promise((resolve, reject) => {
        if (!this.billList.length) {
          this.showWarning = true;
          reject(new Error('fail'));
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.gap-12 {
  margin-bottom: 12px;
}

.bill_info_wrapper {
  background-color: #fff;
  padding: 12px;
  margin-bottom: 6px;

  .title {
    font-weight: bold;
    display: flex;
    align-items: center;

    .ant-alert {
      flex: 1;
      margin-left: 12px;
    }
  }
}
</style>

