import Enum from '@/utils/Enum';

const PurchasePaymentMethodEnum = [
  {
    value: 0,
    name: '不限',
  },
  {
    value: 1,
    name: '预收款',
  },
  {
    value: 2,
    name: '账期收款',
  },
  {
    value: 3,
    name: '背靠背',
  },
];

export default new Enum(PurchasePaymentMethodEnum);
export { PurchasePaymentMethodEnum };
